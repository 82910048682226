import React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"

import Layout from "../components/Layout"

import Gallery from "../components/Gallery"

import Button from "../components/misc/CustomButton"
import { Link } from "gatsby"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export default ({ data }) => {
  const images = data.allContentfulBranding.edges
    .filter(({ node }) => node?.image != null)
    .map(({ node }) => ({
      fluid: node.image.localFile.childImageSharp.fluid,
      id: node.image.id,
      name: node.image.title,
      gridWidth: node.gridWidth,
      gridHeight: node.gridHeight,
      order: node.order,
    }))
    .sort((a, b) => a.order - b.order)

  return (
    <Layout
      title={
        <>
          BARSKA
          <br />
          PHOTO
          <br />
          STORIES
        </>
      }
      fluid={true}
    >
      <Container>
        <Gallery images={images} />
      </Container>
      <Button style={{ paddingTop: "10vh", paddingBottom: "10vh" }}>
        <Link to="/photography">Return to main page</Link>
      </Button>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulBranding(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            id
            node_locale
          }
          gridWidth
          gridHeight
          order
        }
      }
    }
  }
`
